@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Oswald:wght@300&family=Poppins:wght@200;500&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  width: 100vw;
  height: 100vh;
}

.portfolio {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -24px; /* gutter size offset */
  width: auto;
  margin-top: 2rem;
}
.portfolio_column {
  padding-left: 24px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.portfolio_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 24px;
}

.portfolio .item{
  animation-name: loadingBg;
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  transition: 500ms;
  min-height: 100px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio .item-loaded{
  animation: none;
  background-color: rgba(0, 0, 0, 0);
  min-height: none;
}

@keyframes loadingBg {
  0%{
    background-color: rgba(000, 0, 0, 0);
  }
  100%{
    background-color: rgba(233, 233, 233, 1);
  }
}

.portfolio .item .overlay{
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  color: #fff;
  background-color: rgba(0,0,0,0);
  padding: 1rem;
  overflow: hidden;
  transition: 500ms;
  border-radius: 1.5rem;
}

.portfolio .item:hover .overlay{
  background-color: rgba(0,0,0,0.5);
}

.portfolio .item .overlay .inner{
  width: 100%;
  height: 100%;
  position: relative;
  top: 2rem;
  opacity: 0;
  transition: 500ms;
}
.portfolio .item:hover .overlay .inner{
  top: 0px;
  opacity: 1;
}
.portfolio .item .cover-image-holder{
  width: 100%;
  height: auto;
  opacity: 0;
  transition: 500ms;
}
.portfolio .item .cover-image{
  position: absolute;
  width: 100%;
  height: auto;
  transition: 500ms;
  opacity: 0;
  border-radius: 1.5rem;
}

.portfolio .item:hover .cover-image{
  width: 120%;
  transform: rotateZ(3deg);
}

.portfolio .tags-container{
  bottom: 0px;
  position: absolute;
  padding: 1rem 0rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.portfolio .content .tags-container{
  bottom: unset;
  position: relative;
  padding: 1rem 0rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tags-container > span,
.tags
{
  margin: 0px 6px 6px 0px;
  padding: 2px 12px;
  border-radius: 99px;
  background-color: #eee;
  color: #555;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 12px;
}
.filter-container{

}
.filter-container .filter{
  padding: 0px 1rem;
}
.filter-container .filter.active{

}
.MuiToggleButton-root{
  border: 0px;
}
.portfolio .item .content{
  background-color: #ddd;
  position: fixed;
  z-index: 999;
  overflow: scroll;
  max-width: 100vw;
  max-height: 100vh;
  font-family: 'Poppins', sans-serif;
}
.custom-paper{
  padding: 2rem 4rem;
  background-color: #fff;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.exp-item{
  padding: 2rem 0rem;
  border-bottom: 1px solid #ccc;
}
.exp-item:last-of-type{
  border-bottom: none;
}
.summary-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.summary-container .item-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 20%; */
  width: fit-content;
  text-align: center;
}
.core{
  list-style: none;
  padding: 1rem;
  font-size: min(1.3rem, 4vw);
  border-bottom: 1px dashed #666;
}