@media only screen and (max-width: 600px) {
    .custom-paper{
        padding: 2rem;
    }
    .summary-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .summary-container .item-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        margin-bottom: 4rem;
    }
}